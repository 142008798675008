:root {
  --seagreen: #1a8f88;
  --dark-gray: #646464;
  --shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  --vk-color: #4c75a3;
  --tg-color: #0088cc;
  --linkedin-color: #0e76a8;
  --gh-color: #24292f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
  position: relative;
  padding-bottom: 70px;
}
