.language__img {
  display: block;
  width: 27px;
  height: 27px;
  position: relative;
}
  
.language__title {
  display: inline-block;
  margin-left: 15px;
}

.header__lang-button.MuiButton-root {
  min-width: 33px !important;
  width: 33px !important;
  height: 33px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  background-color: #fff !important;
}

.MuiMenuItem-root {
  font-weight: 300 !important;
}