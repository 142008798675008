:root {
  --info-pad: 25px;
}

.about {
  margin-top: 40px;
  width: 100%;
  background-color: #fff;
  display: flex;
  box-shadow: var(--shadow);
  position: relative;
}

.about__img {
  height: 330px;
}

.info {
  padding: var(--info-pad);
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
}

.info__name {
  font-weight: 300;
  font-size: 25px;
  line-height: 29px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: var(--dark-gray);
}

.info__name_bold {
  color: var(--seagreen);
  font-weight: 700;
}

.info__position {
  margin-top: 6px;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: var(--dark-gray);
}

.info__divider.MuiDivider-root {
  margin: 24px 0;
}

.info__details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  font-size: 15px;
  line-height: 18px;
  color: var(--dark-gray);
  font-weight: 300;
}

.info__details_bold {
  font-weight: 500;
}

.info__links {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 58px;
  background-color: var(--seagreen);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.info__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 41px;
  width: 41px;
  transition: background-color 0.3s ease-in-out;
}

.info__link-img {
  display: flex;
  height: 25px;
  justify-content: center;
  align-items: center;
}

.info__link_vk:hover {
  background: var(--vk-color);
}

.info__link_tg:hover {
  background: var(--tg-color);
}

.info__link_git:hover {
  background: var(--gh-color);
}

.info__link_linkedin:hover {
  background: var(--linkedin-color);
}

.info__link_inst:hover {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

@media (max-width: 750px) {
  .about {
    flex-direction: column;
    padding-bottom: 56px;
    min-height: auto;
  }

  .about__img {
    height: unset;
    width: 100%;
  }

  .info__links {
    bottom: -56px;
  }
}
