.skills__item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 14px;
}

.skills__item {
  background-color: #fff;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow);
  transform: scaleX(1);
  transition: transform 0.2s ease-in-out;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.skills__item:hover {
  background-color: rgb(240, 240, 240);
}

.skills__item[aria-pressed="true"] {
  filter: invert(1);
}

.skills__item_hidden {
  transform: scaleX(0);
}

.skills__img {
  display: block;
  height: 60px;
  width: 60px;
}

.skills__title {
  font-size: 12px;
  font-weight: 300;
  max-width: 90px;
  text-align: center;
  margin-bottom: 0;
  /*height: 14px;*/
}
