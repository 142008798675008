.container {
  width: 100%;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  background-color: var(--seagreen);
  height: 280px;
  width: 100%;
}

.content {
  position: relative;
  margin: auto;
  width: 800px;
  max-width: 90vw;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding-top: 40px;
}

.header {
  color: #fff;
  letter-spacing: 0.08em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.content__title {
  margin: 70px auto 25px;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--seagreen);
}

.content__experience {
  display: grid;
  grid-gap: 20px;
}

.MuiTypography-body1 {
  font-weight: 300 !important;
}
