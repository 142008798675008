.skills {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  grid-auto-rows: 130px;
  grid-gap: 15px;
  overflow: hidden;
  padding-bottom: 14px;
}

.skills__animation-container {
  position: relative;
}

.skills__animation {
  position: absolute;
  top: 1000px;
  transition: top 0.5s ease-in-out;
}

.skills__animation_end {
  position: relative;
  top: 0;
}

.skills__divider {
  display: flex;
  align-items: center;
}

.skills__divider hr {
  width: 1px;
  height: 70px;
  background: rgba(0, 0, 0, 0.15);
  border: none;
  transition: transform 0.3s ease-in-out;
}

.skills__divider[aria-hidden="true"] hr {
  transform: scaleY(0);
}
